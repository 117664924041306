import styled from 'styled-components';

export const ScHomeHeroWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 55px;
	box-sizing: border-box;
	gap: 45px;
	position: relative;
	@media (min-height: 500px) and (min-width: 390px) {
		height: calc(100svh - 65px);
	}
	justify-content: space-between;
	button {
		height: 100%;
	}
	img {
		width: 290px;
		height: auto;
		margin-left: 20px;
		margin-top: -65px;
		z-index: 5;
	}
	@media (min-width: 768px) {
		height: 100%;
		@media (min-height: 1000px) {
			height: calc(100svh - 95px);
		}
		img {
			width: 80%;
		}
	}
	@media (min-width: 1024px) {
		@media (min-height: 600px) {
			height: calc(100vh - 95px);
		}
		img {
			margin: 0;
			position: absolute;
			left: -4px;
			bottom: 50px;
			width: 600px;
		}
	}
	@media (min-width: 1220px) {
		padding: 30px 0;
		gap: 20px;
		@media (min-height: 500px) {
			height: calc(100vh - 120px);
		}
	}
	@media (min-width: 1920px) {
		img {
			width: 765px;
		}
	}
	@media (min-width: 1220px) and (max-width: 1919px) and (max-height: 800px) {
		img {
			width: 400px;
		}
		.hero-title {
			font: ${({ theme }) => theme.fonts.largeHeading.smallDesktop} !important;
		}
	}
	.buttons {
		@media (min-width: 768px) {
			z-index: 10;
		}
	}
`;

export const ScHomeHeroButtons = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 8px;
	@media (min-width: 768px) {
		gap: 24px;
	}
	@media (min-width: 1220px) {
		margin-top: auto;
		margin-bottom: 50px;
	}
`;

export const ScWeHelpWrapper = styled.div`
	padding: 55px 0;
	box-sizing: border-box;
	@media (min-width: 1220px) {
		padding: 120px 0 140px 0;
	}
	@media (min-width: 1920px) {
		padding: 160px 0 140px 0;
	}
`;

export const ScWeHelpContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	gap: 24px;
	max-width: 1000px;
	margin: 0 auto;
	h3 {
		max-width: 700px;
	}
	@media (min-width: 1220px) {
		gap: 40px;
	}
	@media (min-width: 1440px) {
		h3 {
			max-width: 770px;
		}
	}
	@media (min-width: 1920) {
		gap: 54px;
	}
`;

export const ScWeHelpIcons = styled.div`
	display: flex;
	flex-direction: row;
	gap: 35px;
	align-items: center;
	margin: 0 auto;
	margin-top: 6px;
	.iso {
		width: 100px;
		height: auto;
	}
	.bcorp {
		width: 45px;
		height: auto;
	}
	@media (max-width: 500px) {
		img:first-child {
			max-width: 100px
		}
		img:last-child {
			max-width: 64px
		}
	}

	@media (max-width: 768px) {
		img:first-child {
			max-width: 150px
		}
		img:last-child {
			max-width: 164px
		}
	}
	@media (min-width: 768px) {
		gap: 80px;
		margin-top: 40px;
		.iso {
			width: 230px;
		}
		.bcorp {
			width: 100px;
		}
	}
	@media (min-width: 1920px) {
		margin-top: 90px;
	}
`;

export const ScHowDoesThatWorkWrapper = styled.div`
	background: ${({ theme }) => theme.colors.white};
	padding: 40px 0;
	@media (min-width: 1220px) {
		padding: 40px 0 80px 0;
	}
`;

export const ScHowDoesThatWorkContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 100vw;
	overflow: hidden;
	svg {
		width: 100vw;
		height: auto;
	}
`;

export const ScHowDoesThatWorkContent = styled.div`
	display: flex;
	flex-direction: column-reverse;
	gap: 55px;
	align-items: center;
	margin-bottom: 50px;
	img {
		width: 200px;
		height: auto;
	}
	@media (min-width: 1220px) {
		img {
			width: 100%;
			height: auto;
		}
		display: grid;
		grid-template-columns: 9fr 6fr;
		gap: 60px;
		align-items: end;
		margin: 0 50px;
		margin-bottom: 100px;
		p {
			max-width: 550px;
		}
	}
	@media (min-width: 1920px) {
		align-items: start;
		margin: 0 160px;
		margin-bottom: 100px;
		gap: 30px;
	}
`;

export const ScHowDoesThatWorkTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
	@media (min-width: 1220px) {
		gap: 20px;
	}
	@media (min-width: 1920px) {
		gap: 65px;
		p {
			max-width: 730px;
		}
	}
`;

export const ScReadyToStartSavingWrapper = styled.div`
	background: ${({ theme }) => theme.colors.marmelade};
	border-radius: 10px;
	padding: 24px 15px 50px 15px;
	box-sizing: border-box;
	margin-bottom: 20px;
	margin-top: 40px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	img {
		width: 225px;
		height: auto;
	}
	@media (min-width: 1220px) {
		border-radius: 30px;
		padding: 40px 60px;
		display: grid;
		grid-template-columns: 9fr 6fr;
		gap: 150px;
		img {
			width: 100%;
			height: auto;
			margin: auto 0;
		}
		margin-top: 80px;
	}
	@media (min-width: 1440px) {
		margin-top: 120px;
		padding: 54px 24px 67px 56px;
		grid-template-columns: 10fr 7fr;
	}
	@media (min-width: 1920px) {
		grid-template-columns: 63fr 58fr;
		img {
			margin: 0;
		}
		gap: 450px;
	}
`;

export const ScReadyToStartSavingTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
	align-items: center;
	button {
		width: 145px;
	}
	@media (min-width: 768px) {
		button {
			width: fit-content;
		}
	}
	@media (min-width: 1220px) {
		align-items: start;
		gap: 40px;
		p {
			max-width: 480px;
		}
	}
	@media (min-width: 1440px) {
		align-items: start;
		gap: 40px;
		p {
			max-width: 590px;
		}
	}
	@media (min-width: 1920px) {
		.text {
			max-width: 687px;
		}
		p {
			max-width: 620px;
		}
	}
`;

export const ScReadyToStartSavingButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 30px;
	width: fit-content;
	@media (min-width: 768px) {
		flex-direction: row;
		gap: 24px;
	}
	@media (min-width: 1220px) {
		margin-top: 50px;
		flex-wrap: wrap;
		gap: 10px;
	}
	@media (min-width: 1440px) {
		margin-bottom: 20px;
	}
`;

interface IScTestimonialsWrapper {
	isLargeTop?: boolean;
}

export const ScTestimonialsWrapper = styled.div<IScTestimonialsWrapper>`
	display: flex;
	flex-direction: column;
	gap: 25px;
	align-items: center;
	overflow: hidden;
	@media (max-width: 767px) {
		margin-top: 40px;
		@media (max-height: 649px) {
			margin-top: ${({ isLargeTop }) => isLargeTop && 'calc(-100vh + 710px)'};
		}
		@media (min-height: 650px) {
			margin-top: ${({ isLargeTop }) => isLargeTop && 'calc(-100vh + (520px + 180px))'};
		}
	}
	@media (min-width: 1220px) {
		gap: 80px;
		margin-top: 100px;
	}
	@media (min-width: 1920px) {
		gap: 100px;
		margin-top: 200px;
	}

	.slick-list {
		display: flex;
		width: calc(100% + 20px);
	}
	.slick-track {
		display: flex;
		flex-direction: row;
	}
	.slick-slide {
		> div {
			height: 100%;
		}
	}
`;

export const ScTestimonialsSliderWrapper = styled.div`
	width: 100%;
	.slick-slider {
		padding-bottom: 32px;
		@media (min-width: 1220px) {
			padding-bottom: 68px;
		}
	}
	.slick-dots {
		bottom: 0;
	}
	.slick-dots li {
		width: 18px;
		height: 5px;
		border-radius: 10px;
		cursor: pointer;
		background: ${({ theme }) => theme.colors.darkText};
		margin: 0 4px;
		@media (min-width: 1220px) {
			background: ${({ theme }) => theme.colors.darkText};
			width: 60px;
			height: 12px;
			margin: 0 12px;
		}
	}
	.slick-dots .slick-active div {
		background: ${({ theme }) => theme.colors.marmelade};
	}
	.slider-wrappper {
		height: 100%;
		transform: none;
	}
`;

export const ScTestimonialsSliderDot = styled.div`
	width: 18px;
	height: 5px;
	border-radius: 10px;
	cursor: pointer;
	background: ${({ theme }) => theme.colors.darkText};
	@media (min-width: 1220px) {
		background: ${({ theme }) => theme.colors.darkText};
		width: 60px;
		height: 12px;
	}
`;

export const ScOurPromiseToYouItemsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	> div {
		height: 100%;
	}
	@media (min-width: 1220px) {
		gap: 20px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
`;
